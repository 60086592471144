/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';

// Utilities
@import 'node_modules/bootstrap/scss/utilities/api';

@import 'custom/index';


.coming-soon {
  padding: 80px 60px;
  text-align: center;

  img {
    display: block;
    margin: 0 auto 42px;
  }

  p {
    font-size: x-large;
    font-weight: 700;
  }

  button {
    padding: 12px 24px;
    margin: 16px auto;
    border-radius: 25px;
    background-color: black;
    color: white;
    font-weight: 700;
  }
}